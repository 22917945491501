import {createFeatureSelector, createSelector} from "@ngrx/store";
import {IFeatureTogglesState} from "./feature-toggles.state";
import {AvailableToggle} from "../../../shared/model/feature-toggle.model";

export const featureTogglesSelector = createFeatureSelector<IFeatureTogglesState>('featureToggles');

export const getFeatureToggle = (id: AvailableToggle)=> createSelector(
  featureTogglesSelector,
  state => state.featureToggles.find(featureToggle => featureToggle.id === id),
);

export const hasToggles= createSelector(
  featureTogglesSelector,
  state => state.featureToggles.length > 0,
);
