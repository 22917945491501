import {environment} from "../../../environments/environment";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IFeatureToggle} from "../../shared/model/feature-toggle.model";

const BASE_URL: string = `${environment.apiUrl}/toggles`;

@Injectable({ providedIn: 'root' })
export class FeatureTogglesRepository {
  constructor(private http: HttpClient) { }

  public getFeatureToggles(): Observable<IFeatureToggle[]> {
    return this.http.get<IFeatureToggle[]>(BASE_URL);
  }
}
