import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {getUserInfoAction} from './core/store/auth/auth.actions';
import {TitleService} from "./shared/services/title/title.service";
import {getFeatureTogglesAction} from "./core/store/feature-toggles/feature-toggles.actions";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  public title = 'serve-ui';

  constructor(private store: Store, private _titleService: TitleService) { }


  ngOnInit() {
    this.store.dispatch(getUserInfoAction());
    this.store.dispatch(getFeatureTogglesAction());
  }
}
