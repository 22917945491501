import {createAction, props} from "@ngrx/store";
import {IFeatureToggle} from "../../../shared/model/feature-toggle.model";
import {HttpErrorResponse} from "@angular/common/http";

export const getFeatureTogglesAction = createAction(
  '[Feature Toggles] Get Feature Toggles',
);

export const getFeatureTogglesSuccessAction = createAction(
  '[Feature Toggles] Get Feature Toggles Success',
  props<{ data: IFeatureToggle[]}>()
);

export const getFeatureTogglesFailureAction = createAction(
  '[Feature Toggles] Get Feature Toggles Failure',
  props<{ error: HttpErrorResponse}>()
);
