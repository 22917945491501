import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {FeatureTogglesRepository} from "../../../repository/feature-toggles.repository";
import {
  getFeatureTogglesAction,
  getFeatureTogglesFailureAction,
  getFeatureTogglesSuccessAction
} from "../feature-toggles.actions";
import {concatLatestFrom} from "@ngrx/operators";
import {hasToggles} from "../feature-toggles.selectors";
import {exhaustMap, filter, map, of} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {catchError} from "rxjs/operators";

@Injectable()
export class FeatureTogglesEffect {

  constructor(private actions$: Actions,
              private store: Store,
              private repo: FeatureTogglesRepository) {
  }

  // noinspection TypeScriptValidateTypes
  getToggles$ = createEffect(() => this.actions$.pipe(
    ofType(getFeatureTogglesAction),
    concatLatestFrom(() => this.store.select(hasToggles)),
    filter(([action, hasToggles]) => !hasToggles),
    exhaustMap(([action,]) => {
      return this.repo.getFeatureToggles().pipe(
        map(response => getFeatureTogglesSuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(getFeatureTogglesFailureAction({error: errorResponse})))
      )
    })
  ));

}
