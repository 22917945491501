import {IFeatureTogglesState} from "./feature-toggles.state";
import {Action, createReducer, on} from "@ngrx/store";
import {getFeatureTogglesFailureAction, getFeatureTogglesSuccessAction} from "./feature-toggles.actions";

export const initialFeatureToggleState: IFeatureTogglesState = {
  featureToggles: [],
  error: undefined
}

const featureToggleReducer = createReducer(
  initialFeatureToggleState,

  on(getFeatureTogglesSuccessAction,
    (state, action): IFeatureTogglesState => ({
      ...state,
      featureToggles: action.data
    })),

  on(getFeatureTogglesFailureAction,
    (state, action): IFeatureTogglesState => ({
      ...state,
      error: action.error
    })),
);

export function featureTogglesReducers(state: IFeatureTogglesState = initialFeatureToggleState, action: Action) {
  return featureToggleReducer(state, action);
}
